// doNotTrack polyfill
// https://gist.github.com/pi0/a76fd97c4ea259c89f728a4a8ebca741

export function injectA(data) {
  const page = _.cloneDeep(_.get(unref(data), ["pageQuery", "page"], {}));

  page.meta = page.meta || {};
  if (!page.meta.script || !Array.isArray(page.meta.script)) page.meta.script = [];
  if (typeof window === "undefined")
    page.meta.script.push({
      type: "text/javascript",
      id: "conf",
      innerHTML: `window.hostBase="${page.hostname}";`,
    });

  if (!page.meta.link || !Array.isArray(page.meta.link)) page.meta.link = [];
  page.meta.link.push({
    rel: "manifest",
    href: "/api/pwa/manifest.webmanifest",
    // crossorigin: "use-credentials"
  });

  if (page.anl && page.hostname && page.uri && page.anl.id) {
    if (page.anl.type === "gtm") {
      if (typeof window !== "undefined") {
        // console.log("ping gtm", window.dataLayer);

        (window.dataLayer = window.dataLayer ?? []).push({
          // event: "virtualPageview",
          // pageUrl: window.document.location.href,
          // pageTitle: page.title,

          // event: "page_view",
          // page_title: page.title,
          // page_location: window.document.location.href,
          // page_location: `https://${page.hostname}/${page.uri === "index.html" ? "" : page.uri}`,

          event: "content-view",
          "content-name": `https://${page.hostname}/${page.uri === "index.html" ? "" : page.uri}`,
          "content-view-name": page.title,
        });
      } else {
        // console.log("inject gtm");
        page.meta.script.push(
          { async: true, src: "https://www.googletagmanager.com/gtag/js?id=" + page.anl.id },
          {
            type: "text/javascript",
            id: "gtm",
            innerHTML: `window.dataLayer = window.dataLayer || [];
function gtag(){dataLayer.push(arguments);}
gtag('js', new Date());
gtag('config', '${page.anl.id}', {'send_page_view': true});`,
          }
        );
        // {
        // type: "text/javascript",
        // async: true,
        // id: "gtm",
        // innerHTML: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
        // new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
        // j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
        // 'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        // })(window,document,'script','dataLayer','${page.anl.id}');`,
        // }
      }
    } else if (page.anl.type === "matomo") {
      if (typeof window !== "undefined") {
        // console.log("ping matomo", window._paq);
        if (window._paq) {
          // console.log("window._paq", window._paq);
          window._paq.push(["setCustomUrl", `https://${page.hostname}/${page.uri === "index.html" ? "" : page.uri}`]);
          window._paq.push(["trackPageView"]);
        }
      } else {
        // console.log("inject matomo");

        const matomoBase = "https://piwik4.pcsol.com/piwik/";
        page.meta.script.push({
          type: "text/javascript",
          async: true,
          id: "matomo",
          innerHTML: `
  var _paq = window._paq = window._paq || [];
  /* tracker methods like "setCustomDimension" should be called before "trackPageView" */
  _paq.push(['enableLinkTracking']);
  (function() {
    var u="${matomoBase}";
    _paq.push(['setTrackerUrl', u+'matomo.php']);
    _paq.push(['setSiteId', '${page.anl.id}']);
    var d=document, g=d.createElement('script'), s=d.getElementsByTagName('script')[0];
    g.async=true; g.src=u+'matomo.js'; s.parentNode.insertBefore(g,s);
  })();`,
          tagPosition: "head", // bodyClose | bodyOpen | head
        });
      }
    }
  }
  return page;
}
