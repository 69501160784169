
import { updateAppConfig } from '#app/config'
import { defuFn } from 'defu'

const inlineConfig = {
  "buildDate": "2024-12-09T14:08:02.288Z",
  "nuxt": {
    "buildId": "f86e0df4-894e-486d-8a2e-2ae11bbc98d8"
  }
}

// Vite - webpack is handled directly in #app/config
if (import.meta.hot) {
  import.meta.hot.accept((newModule) => {
    updateAppConfig(newModule.default)
  })
}



export default /* #__PURE__ */ defuFn(inlineConfig)
