import {aliases,mdi} from 'vuetify/iconsets/mdi-svg'
import {mdiAccount,mdiHome,mdiEggEaster,mdiTrophy,mdiMenu,mdiCash100,mdiThumbUp,mdiHeart,mdiNewBox,mdiCloseCircle,mdiStarCircle,mdiChevronUp,mdiChevronDown,mdiChevronRight,mdiChevronDoubleRight,mdiBootstrap,mdiCashRefund,mdiGiftOutline,mdiWhiteBalanceSunny,mdiAccountCheck,mdiCloseOctagonOutline,mdiPercentOutline,mdiAsterisk,mdiEggOutline,mdiAutorenew,mdiCardsClubOutline,mdiBookmarkMultipleOutline,mdiBookHeartOutline,mdiCalendarRange,mdiTrafficCone,mdiPowerPlugOutline,mdiCheck,mdiClose,mdiCardsSpadeOutline,mdiCardsSpade} from '@mdi/js'
export const enabled = true
export const isDev = false
export function iconsConfiguration() {

  return {
    defaultSet: 'mdi',
    aliases: {
      ...aliases,
      account: mdiAccount,
home: mdiHome,
eggEaster: mdiEggEaster,
trophy: mdiTrophy,
menu: mdiMenu,
cash100: mdiCash100,
thumbUp: mdiThumbUp,
heart: mdiHeart,
newBox: mdiNewBox,
closeCircle: mdiCloseCircle,
starCircle: mdiStarCircle,
chevronUp: mdiChevronUp,
chevronDown: mdiChevronDown,
chevronRight: mdiChevronRight,
chevronDoubleRight: mdiChevronDoubleRight,
bootstrap: mdiBootstrap,
cashRefund: mdiCashRefund,
gift: mdiGiftOutline,
sun: mdiWhiteBalanceSunny,
personCheck: mdiAccountCheck,
xOctagon: mdiCloseOctagonOutline,
percent: mdiPercentOutline,
asterisk: mdiAsterisk,
egg: mdiEggOutline,
reload: mdiAutorenew,
suitClub: mdiCardsClubOutline,
bookmark: mdiBookmarkMultipleOutline,
bookmarkHeart: mdiBookHeartOutline,
calendarRange: mdiCalendarRange,
coneStriped: mdiTrafficCone,
plug: mdiPowerPlugOutline,
check: mdiCheck,
close: mdiClose,
cardsSpadeOutline: mdiCardsSpadeOutline,
cardsSpade: mdiCardsSpade
    },

    sets: { mdi }
  }
}

